import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  query,
  writeBatch,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAqeO06E_OXSWKMBNmBnIlD7g60fY1jYSY",
  authDomain: "jks-israel-app.firebaseapp.com",
  databaseURL: "https://jks-israel-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "jks-israel-app",
  storageBucket: "jks-israel-app.appspot.com",
  messagingSenderId: "265218182067",
  appId: "1:265218182067:web:762cc3b2e5c0a98ab519c6",
  measurementId: "G-PKJWYNFMEM"
};

const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Init Auth
export const auth = getAuth();

// Images Storage Connection
const storage = getStorage(app);

export const getGroupData = async (userType, ID, groupID) => {
  const groupData = await getDoc(
    doc(
      db,
      Number(userType) !== 3 ? "users" : "organizations",
      ID,
      "groups",
      groupID
    )
  );
  return groupData.data();
};

export const submitNewStudentToGroup = async (
  userType,
  ID,
  groupID,
  studentData,
  image
) => {
  let newStudentRef = doc(
    collection(
      db,
      Number(userType) !== 3 ? "users" : "organizations",
      ID,
      "students"
    )
  );

  const newId = newStudentRef.id;

  // Fetching the image from the device's cache
  const imageRef = await fetch(image);

  // Image Time Stamp
  const imageUploadTime = Date.now();

  // Creating a blob to send to the storage
  const blob = await imageRef.blob();

  // Setting a filename according to the student ID
  const filename = `${newId}${imageUploadTime}.jpg`;

  // Uploading the blob to the storage under the filename
  await uploadBytes(ref(storage, filename), blob);

  // getting the uploaded image URI from the storage
  const imageURI = await getStudentImageURI(newId, imageUploadTime);

  // sending the student's data to the DB
  return await setDoc(newStudentRef, {
    ...studentData,
    studentSource: "web",
    studentCreatedTime: new Date(),
    id: newId,
    imageUploadTime: imageUploadTime,
    imageURI: imageURI,
    groupsIDs: [groupID],
    classPresenceRecords: [],
    status: "toReview",
  })
    .then((res) => {
      console.log(`student was submitted`);
      return true;
    })
    .catch((err) => {
      console.log(`student wasn't submited`);
      return false;
    });
};

// A function the fetches the imageURI of a student's image that is stored on the cloud
export const getStudentImageURI = async (studentID, timeUpload) => {
  return await getDownloadURL(ref(storage, `${studentID}${timeUpload}.jpg`))
    .then((imageURI) => {
      return imageURI;
    })
    .catch(() => {
      return "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
    });
};

//// Managers
// Function to sign in the manager
export const managerSignIn = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    console.log("Manager signed in successfully");
    return user;
    // Additional logic after successful sign-in
  } catch (error) {
    console.log("Error signing in:", error.message);
    // Handle sign-in error
  }
};

// Function to sign up the manager
export const managerSignUp = async (
  email,
  password,
  firstName,
  lastName,
  unionName,
  phoneNumber
) => {
  try {
    // Create a new user with email and password
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    console.log("Manager signed up successfully");

    // Remove password from the data to be stored in Firestore
    const userData = {
      firstName,
      lastName,
      unionName,
      phoneNumber,
      activationStatus: "not active",
    };

    // Create a new document in Firestore under "users/{unionName}"
    const unionDocRef = doc(db, "users", user.uid);
    await setDoc(unionDocRef, userData);

    console.log("Data saved to Firestore");
    return user;
    // Additional logic after successful sign-up and data storage
  } catch (error) {
    console.log("Error signing up:", error.message);
    // Handle sign-up error
  }
};

export const managerLogout = async () => {
  const res = await signOut(auth);
  console.log(res);
};

export const getUserData = async (uid) => {
  try {
    const docRef = doc(db, "users", uid);
    const unionDoc = await getDoc(docRef);
    if (unionDoc.exists) {
      return unionDoc.data();
    } else {
      console.log("User data not found");
      return {
        status: "user does not exist",
      };
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

// Syncing Data

export const syncUserEvents = async (uid, events) => {
  try {
    const eventsCollectionRef = collection(db, "users", uid, "events");
    const eventsQuerySnapshot = await getDocs(eventsCollectionRef);

    const batch = writeBatch(db);
    eventsQuerySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    const eventPromises = events.map(async (event) => {
      const eventDocRef = doc(collection(db, "users", uid, "events"));
      await setDoc(eventDocRef, { ...event, id: eventDocRef.id });
    });
    await Promise.all(eventPromises);
  } catch (error) {
    console.log(error);
  }
};
export const getUserEvents = async (uid) => {
  try {
    const eventsCollectionRef = collection(db, "users", uid, "events");
    const userEventsQuery = query(eventsCollectionRef);
    const userEventsQuerySnapshot = await getDocs(userEventsQuery);

    const userEvents = [];
    userEventsQuerySnapshot.forEach((doc) => {
      const event = doc.data();
      userEvents.push(event);
    });

    return userEvents;
  } catch (error) {
    console.error("Error getting user events:", error);
    return null;
  }
};

export const syncUserCoaches = async (uid, coaches) => {
  try {
    const coachesCollectionRef = collection(db, "users", uid, "coaches");
    const coachesQuerySnapshot = await getDocs(coachesCollectionRef);

    const batch = writeBatch(db);
    coachesQuerySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    const coachPromises = coaches.map(async (coach) => {
      const coachDocRef = doc(collection(db, "users", uid, "coaches"));
      await setDoc(coachDocRef, { ...coach, id: coachDocRef.id });
    });
    await Promise.all(coachPromises);
  } catch (error) {
    console.log(error);
  }
};

export const getUserCoaches = async (uid) => {
  try {
    const coachesCollectionRef = collection(db, "users", uid, "coaches");
    const userCoachesQuery = query(coachesCollectionRef);
    const userCoachesQuerySnapshot = await getDocs(userCoachesQuery);

    const userCoaches = [];
    userCoachesQuerySnapshot.forEach((doc) => {
      const coach = doc.data();
      userCoaches.push(coach);
    });

    return userCoaches;
  } catch (error) {
    console.error("Error getting user coaches:", error);
    return null;
  }
};

export const syncUserGroups = async (uid, groups) => {
  try {
    const groupsCollectionRef = collection(db, "users", uid, "groups");
    const groupsQuerySnapshot = await getDocs(groupsCollectionRef);

    const batch = writeBatch(db);
    groupsQuerySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    const groupPromises = groups.map(async (group) => {
      const groupDocRef = doc(collection(db, "users", uid, "groups"));
      await setDoc(groupDocRef, { ...group, id: groupDocRef.id });
    });
    await Promise.all(groupPromises);
  } catch (error) {
    console.log(error);
  }
};

export const getUserGroups = async (uid) => {
  try {
    const groupsCollectionRef = collection(db, "users", uid, "groups");
    const userGroupsQuery = query(groupsCollectionRef);
    const userGroupsQuerySnapshot = await getDocs(userGroupsQuery);

    const userGroups = [];
    userGroupsQuerySnapshot.forEach((doc) => {
      const group = doc.data();
      userGroups.push(group);
    });
    return userGroups;
  } catch (error) {
    console.error("Error getting user groups:", error);
    return null;
  }
};
